import { Controller } from "stimulus"

export default class extends Controller {
    subscription = null

    initialize() {
        this._createSubscription()
    }

    disconnect() {
        if (this.subscription) window.cable.subscriptions.remove(this.subscription)
    }

    // PRIVATE

    _createSubscription() {
        var $element = $('[data-channel-subscribe="room"]');
        var room_id = $element.data("room-id");
        this.subscription = window.cable.subscriptions.create(
            {channel: 'RoomChannel', room: room_id},
            {received: data => {
                var element = $('[data-channel-subscribe="room"]');
                var content = this._getMessageHtml(data)

                element.append(content);
                document.getElementById( 'chat_box' ).scrollTo(0,document.getElementById( 'chat_box' ).scrollHeight)
                document.getElementById('new_message_form').reset()
                document.getElementById('chat-text').classList.remove("chat-input-enabled")
                document.getElementById('submit-btn-div').classList.remove("submit-chat-enabled")
                $('#form_file_name').text('')
            }}
        )
    }

    _localTimeStamp(value) {
        var local_date = new Date(value)
        return window.moment(local_date).format("MMM D, YYYY [at] h:mma")
    }

    _getMessageHtml(data) {
        var content = ''
        if (data.room_type == 'private') {
            content = this._getPrivateMessageHtml(data)
        } else {
            content = this._getGroupMessageHtml(data)
        }

        return content
    }

    _getPrivateMessageHtml(data){
        var message = data.message
        var message_txt = ''
        message_txt = this._doLinkfy(message.content)
        var content = ''
        if (current.assignment.id != message.assignment_id) {
            content = `<div class="media w-50 mb-3">
                            <div class="media-body ml-3 receiver-messages">
                                 <div class="bg-light rounded py-2 px-3 mb-2">
                                      <span class="message-time">
                                          <time datetime="${message.created_at}" data-local="time" data-format="%b %e, %Y at %l:%M%P" title="${this._localTimeStamp(message.created_at)}" data-localized="" aria-label="${this._localTimeStamp(message.created_at)}">${this._localTimeStamp(message.created_at)}</time>
                                      </span>
                                      <p class="text-small mb-0 text-muted pt-1">${message_txt}</p>
                                      ${this._getAttachmentHtml(data)}
                                 </div>
                            </div>
                       </div>`
        } else {
            content = `<div class="media w-50 ml-auto mb-3">
                            <div class="media-body sender-messages">
                                <div class="bg-primary rounded py-2 px-3 mb-2">
                                    <i class="fa fa-solid fa-check-double pr-1 icon-color-gray"></i>
                                    <span class="message-time">
                                        <span class="message-status"></span>
                                        <time datetime="${message.created_at}" data-local="time" data-format="%b %e, %Y at %l:%M%P" title="${this._localTimeStamp(message.created_at)}" data-localized="" aria-label="${this._localTimeStamp(message.created_at)}">${this._localTimeStamp(message.created_at)}</time>
                                    </span>
                                    <p class="text-small mb-0 text-white">${message_txt}</p>
                                     ${this._getAttachmentHtml(data)}
                                </div>
                            </div>
                        </div>`
        }

        return content
    }

    _getGroupMessageHtml(data){
        var message = data.message
        var message_txt = ''
        message_txt = this._doLinkfy(message.content)
        var content = ''
        if (current.assignment.id != message.assignment_id) {
            content = `<div class="media w-50 mb-3">
                            <div class="media-body ml-3 receiver-messages">
                                 <div class="bg-light rounded py-2 px-3 mb-2">
                                      <span class="message-time">${data.user_name} &gt;&gt; </span>
                                      <span class="message-time">
                                          <time datetime="${message.created_at}" data-local="time" data-format="%b %e, %Y at %l:%M%P" title="${this._localTimeStamp(message.created_at)}" data-localized="" aria-label="${this._localTimeStamp(message.created_at)}">${this._localTimeStamp(message.created_at)}</time>
                                      </span>
                                      <a data-controller="helpers--links" data-action="helpers--links#click" data-helpers--links-remote-modal="true" data-remote="true" href="/${message.schedule_id}/messages/${message.id}"><span class="icon-ellipsis-circle float-right message-read icon-color-blue"></span></a>
                                      <p class="text-small mb-0 text-muted pt-1">${message_txt}</p>
                                       ${this._getAttachmentHtml(data)}
                                 </div>
                            </div>
                       </div>`
        } else {
            content = `<div class="media w-50 ml-auto mb-3">
                            <div class="media-body sender-messages">
                                <div class="bg-primary rounded py-2 px-3 mb-2">
                                    <i class="fa fa-solid fa-check-double pr-1 icon-color-gray"></i>
                                    <span class="message-time">
                                        <span class="message-time">${data.user_name} &gt;&gt; </span>
                                        <span class="message-status">&gt;</span>
                                        <time datetime="${message.created_at}" data-local="time" data-format="%b %e, %Y at %l:%M%P" title="${this._localTimeStamp(message.created_at)}" data-localized="" aria-label="${this._localTimeStamp(message.created_at)}">${this._localTimeStamp(message.created_at)}</time>
                                    </span>
                                    <a data-controller="helpers--links" data-action="helpers--links#click" data-helpers--links-remote-modal="true" data-remote="true" href="/${message.schedule_id}/messages/${message.id}"><span class="icon-ellipsis-circle float-right message-read icon-color-blue"></span></a>
                                    <p class="text-small mb-0 text-white">${message_txt}</p>
                                     ${this._getAttachmentHtml(data)}
                                </div>
                            </div>
                        </div>`
        }

        return content
    }

    _getAttachmentHtml(data){
        var message = data.message
        var content = ''
        if (data.attachment == true) {
            content = `<p>
                            <a target="_blank" data-controller="helpers--links" data-action="helpers--links#click" href="/${message.schedule_id}/messages/${message.id}/download_attachment">
                                <span>${data.file_name}</span>
                                <span class="icon-cloud-download"></span>
                            </a>
                        </p>`
        }

        return content
    }

    _doLinkfy(inputText) {
        //URLs starting with http://, https://, or ftp://
        var replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
        var replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')

        //URLs starting with www. (without // before it, or it'd re-link the ones done above)
        var replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
        var replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>')

        //Change email addresses to mailto:: links
        var replacePattern3 = /(([a-zA-Z0-9_\-\.]+)@[a-zA-Z_]+?(?:\.[a-zA-Z]{2,6}))+/gim
        var replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>')

        return replacedText
    }


}